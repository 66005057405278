"use client"

import { BoltaError, BoltaErrorProps } from "./BoltaError"

export type NotFoundProps = RequiredBy<BoltaErrorProps, "title" | "message">

export function NotFound({ title, message, children }: BoltaErrorProps) {
  return (
    <BoltaError title={title} message={message}>
      {children}
    </BoltaError>
  )
}
