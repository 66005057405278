export const useChannelTalk = () => {
  const openMessenger = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ChannelIO) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (window as any).ChannelIO("showMessenger")
    }
  }

  const openSupportBot = async (supportBotId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).ChannelIO) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new (window as any).ChannelIO("openSupportBot", supportBotId)
    }
  }

  return { openMessenger, openSupportBot }
}
