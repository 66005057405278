import { Button } from "@nextui-org/react"
import * as Sentry from "@sentry/nextjs"
import { QueryErrorResetBoundary } from "@tanstack/react-query"
import * as React from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"

import { useChannelTalk } from "@/hooks/useChannelTalk"

import { BoltaError } from "./BoltaError"

export type BoltaErrorBoundaryProps = {
  children: React.ReactNode

  onError?: (error: Error) => void

  fallbackRender?: (props: FallbackProps) => React.ReactNode
}

export const BoltaErrorBoundary = ({
  children,
  onError,
  fallbackRender,
}: BoltaErrorBoundaryProps) => {
  const channelTalk = useChannelTalk()

  const handleError = (error: Error) => {
    onError?.(error)
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error)
    }
  }

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => {
            if (fallbackRender) {
              return fallbackRender({ error, resetErrorBoundary })
            }
            return (
              <BoltaError
                title={
                  error?.title ||
                  "서비스 이용에 불편을 드려 죄송합니다. 예기치 못한 오류가 발생하였습니다."
                }
                message={
                  "관련 문의 사항이 있으시다면 문의하기를 이용해 주시기 바랍니다."
                }
                error={error}
              >
                <div className="flex gap-[20px]">
                  <Button
                    size="lg"
                    color="default"
                    variant="bordered"
                    className="h-[44px]"
                    onClick={() => {
                      channelTalk.openMessenger()
                    }}
                  >
                    문의하기
                  </Button>
                  <Button
                    size="lg"
                    color="primary"
                    className="h-[44px]"
                    onClick={() => {
                      resetErrorBoundary()
                    }}
                  >
                    재시도하기
                  </Button>
                </div>
              </BoltaError>
            )
          }}
          onError={handleError}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}
