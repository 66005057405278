import * as React from "react"
import clsx from "clsx"
import { AxiosError } from "axios"

import { AlertTriangleIcon } from "@/components/Icons"
import { ApiErrorResponse } from "@/clients/types"

export type BoltaErrorProps = {
  /**
   * 에러 타이틀
   */
  title?: React.ReactNode
  /**
   * 에러에 대한 메시지를 넣을 수 있습니다.
   * @default "예기치 못한 에러가 발생했습니다."
   */
  message?: React.ReactNode
  /**
   * ActionButton 혹은 다른 컴포넌트를 넣을 수 있습니다.
   */
  children?: React.ReactNode
  /**
   * 에러
   */
  error?: Error | AxiosError<ApiErrorResponse>
}

export function BoltaError({ children, message, title = "ERROR" }: BoltaErrorProps) {
  return (
    <section
      className={clsx(
        "flex flex-col justify-center",
        "mx-auto h-[calc(100vh-120px)]",
        "gap-20",
      )}
    >
      <div className={clsx("flex flex-col items-center justify-center gap-10")}>
        <div
          className={clsx(
            "flex items-center justify-center",
            "h-[80px] w-[80px]",
            "rounded-2xl bg-[#FFF2F2]",
            "border border-red-03",
          )}
        >
          <AlertTriangleIcon className="text-[32px]" />
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <div>
            {title && (
              <h1
                className={clsx(
                  "max-w-96",
                  "typography-heading-3-bold",
                  "text-gray-11",
                  "text-center",
                  "break-words",
                  "py-0",
                )}
              >
                {title}
              </h1>
            )}
          </div>
          <p
            className={clsx(
              "max-w-[496px]",
              "typography-subheading-2-medium",
              "break-words text-center text-gray-08",
            )}
          >
            {message || "예기치 못한 에러가 발생했습니다."}
          </p>
        </div>
      </div>
      <div className="flex justify-center">{children}</div>
    </section>
  )
}
